





































































































































import api from "@/api"; //ABP API接口
import { Vue, Component, Ref } from "vue-property-decorator";
import {
  CapitalIncomeRecordFromOtherDto,
  DataDictionaryDtoPagedResultDto,
  UserTaskDto,
} from "@/api/appService";
import PagedTableView from "@/components/PagedTableView/index.vue";
import AbSelect from "@/components/AbSelect/index.vue";
import ExportButton from "@/components/ExportButton/index.vue";
import AgileAuditTag from "@/components/AgileAuditTag/index.vue";

@Component({
  name: "UserTaskList",
  components: {
    PagedTableView,
    AbSelect,
    ExportButton,
    AgileAuditTag,
  },
  filters: {
    formatType(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.id == type) {
          result = item.displayName;
          return;
        }
      });
      return result;
    },
    formatUser(type: string, typeList: any[]) {
      let result = "";
      typeList.forEach((item) => {
        if (item.value == type) {
          result = item.text;
          return;
        }
      });
      return result;
    },
  },
})
export default class UserTaskList extends Vue {
  importantLevel: any = []; // 紧急程度
  queryForm = {};
  userList: any[] = [];
  statusList: any[] = [];

  created() {
    this.fetchDataDictionary();
    this.fetchUserList();
    this.fetchEnumType();
  }

  async fetchUserList() {
    await api.fundUser.getFundUserList({}).then((res: any) => {
      this.userList = res ?? [];
    });
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "TaskStatus",
      })
      .then((res) => {
        this.statusList = res;
      });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "ImportantLevel",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.importantLevel = res.items;
      });
  }

  // 获取表数据
  fetchData(params: any) {
    return api.userTask.getMyTask(params);
  }

  // 查看详情
  jumpDetail(row: UserTaskDto) {
    this.$router.push({
      name: "taskManagerDetail",
      params: { id: row.id!.toString() },
    });
  }

  // 认领
  handleClaim(row: UserTaskDto) {
    this.$confirm("确定认领该任务吗?", "提示").then(async () => {
      await api.userTask
        .claim({
          body: { id: row.id },
        })
        .then((res: any) => {
          this.$message({
            type: "success",
            message: "认领成功!",
          });
        });
    });
  }

  // 认领
  handleSubmit(row: UserTaskDto) {
    this.$confirm("确认提交完成此任务吗?", "提示").then(async () => {
      await api.userTask.submit({ body: { id: row.id } }).then((res: any) => {
        this.$message({
          type: "success",
          message: "提交成功!",
        });
      });
    });
  }
}
