import { render, staticRenderFns } from "./myTask.vue?vue&type=template&id=77f8edb2&scoped=true&"
import script from "./myTask.vue?vue&type=script&lang=ts&"
export * from "./myTask.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "77f8edb2",
  null
  
)

export default component.exports